import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import BlogItem from "../components/BlogItem";
import { Helmet } from "react-helmet";

export const AllBlogsQuery = graphql`
  query {
    allCosmicjsPosts(sort: { fields: [published_at], order: DESC }) {
      edges {
        node {
          title
          id
          slug
          content
          published_at(formatString: "DD MMMM, YYYY")
          metadata {
            post_author
            tag

            description
            main_image {
              url
            }
          }
        }
      }
    }
  }
`;
const Blog = ({ data }) => {
  useEffect(() => {
    window.location.href = `/`;
  }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Digital Marketing Consultant Blog</title>
        <meta
          name="description"
          content="Keep up with the Consultants. Stay up to date with content and insights to help you achieve digital marketing success."
        />
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>
      <div className="bloglist bg-white pb-20  lg:pb-28">
        <div class="bg-gradient-to-r from-cyan-500 to-blue-500">
          <h2 className="py-16 text-center text-3xl leading-9 tracking-tight font-extrabold text-white sm:text-5xl sm:leading-10">
            The Consultant's Couch
          </h2>
        </div>
        <div className="px-5 md:px-0 relative max-w-lg mx-auto lg:max-w-7xl">
          <div className="mt-8 grid gap-16 pt-12 lg:grid-cols-2 lg:col-gap-16 lg:row-gap-24">
            {data.allCosmicjsPosts.edges.map((post) => {
              console.log(post.node.metadata.tag);

              return (
                <div>
                  <BlogItem
                    key={post.node.id}
                    title={post.node.title}
                    date={post.node.published_at}
                    author={post.node.metadata.post_author.title}
                    description={post.node.metadata.description}
                    slug={"posts/" + post.node.slug}
                    image={post.node.metadata.main_image.url}
                    category={post.node.metadata.tag.map((tag) => {
                      return <p className="pr-2">{tag + ","}</p>;
                    })}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
